import { buf2hex, hexCharCodeToStr, decToHex } from "../utils/utils";
import { message } from "ant-design-vue";
import inputObject from "../utils/config";
import { addSubLog, updateStatus } from "../../api/collect";
import { createSubOrder } from "@/api/collect";

const serialPort = {
  state: {
    step: 0, // 步骤值
    inputObject,
    writer: null,
    reader: null,

    port: null, // 连接的串口
    isConnect: false, // 是否连接
    equipmentInfo: "", // 设备信息
    dataSource: [], //源数据
    code: "", // 存储接收到的数据的指令
    sendData: [], // 发送的数据
    receiveData: [], // 接收的数据
    hexMessage: "", // 接收到的完整16进制字符串
    checkResults: false, // 检验结果
    isAwait: false, // 是否处于等待接收数据状态（断包时记录该状态）
    timerOut: null, // 数据超时无响应
    support: false, // 是否支持navigator.serial 串口通讯
    isStop: false, // 机器人是否处于暂停状态
    orderInfo: {
      //选择的订单信息
      id: "", //订单id
      sn: "", //订单号
      size: "", //码数
    },
    subOrder: [], //创建的子订单
    currentData: [], // 当前执行的形变值
    orderTimestamp: [], // 订单时间戳
    EventBus: null, //  事件车对象
  },

  mutations: {
    // 保存EventBus
    updateEventBus(state, EventBus) {
      state.EventBus = EventBus;
    },
    // 监听串口的连接的断开
    listenersSerialPort(state) {
      navigator.serial.addEventListener("connect", (event) => {
        message.success("串口设备已插入");
        // TODO: Automatically open event.target or warn user a port is available.
      });

      navigator.serial.addEventListener("disconnect", (event) => {
        // state.isConnect = true
        message.error("串口设备已拔出");
        // console.log('串口已断开')
        // TODO: Remove |event.target| from the UI.
        // If the serial port was opened, a stream error would be observed as well.
      });
    },
    // 移除监听
    removeListenersSerialPort() {
      navigator.serial.removeEventListener("connect", (event) => {
        message.success("串口设备已插入");
        // TODO: Automatically open event.target or warn user a port is available.
      });

      navigator.serial.removeEventListener("disconnect", (event) => {
        // state.isConnect = true
        message.error("串口设备已拔出");
        // console.log('串口已断开')
        // TODO: Remove |event.target| from the UI.
        // If the serial port was opened, a stream error would be observed as well.
      });
    },

    // 修改浏览器是否支持串口通讯的标识
    updateSupport(state, flag) {
      state.support = flag;
    },
    // 添加表格数据
    addDataSource(state, data) {
///

//
      console.log(data);
      state.dataSource = [];
      data.forEach((item, index) => {
        item.step = index + 1;
        item.key = index + 1;
      });
      state.dataSource = data;
      //this.dispatch("createSubOrderFun");
    //  console.log("DATASOURE啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊")
      console.log("dataSource", state.dataSource);
    },
    // 删除表格数据
    deleteDataSource(state, key) {
      let index = state.dataSource.findIndex((item) => {
        return item.key == key;
      });
      let list = state.dataSource;
      list.splice(index, 1);
      state.dataSource = [];
      state.dataSource.push(...list);
    },
    // 修改表格数据
    modifyDataSource(state, obj) {
      let { index, data } = obj;
      let list = state.dataSource;
      list.splice(index, 1, data);
      state.dataSource = [];
      state.dataSource.push(...list);
    },
    // 接收数据
    receiveDataFuc(state, value) {
      console.log("value", value);
      // value 是一个 Uint8Array
      let hexStr = buf2hex(value);
      let msg = "";
      this.commit("dataValidation", hexStr);
      console.log("isAwait", state.isAwait);
      if (state.isAwait) {
        return;
      }
      if (!state.checkResults) {
        state.sendData = [];
        state.receiveData = [];
        console.log("进入了!this.checkResults");
        console.log(state.code)
        if (state.code == "0c") {
          this.dispatch("submitData");
        } else {
         // message.error("指令执行失败，请尝试从新发送");
        }
        return;
      }
      console.log("state.hexMessage", state.hexMessage, hexStr);
      msg = state.hexMessage.slice(6, state.hexMessage.length - 4);
      console.log("指令类型", state.code);
      console.log("返回的信息", msg);

      switch (state.code) {
        case "06": // 获取设备信息
          let info = hexCharCodeToStr(msg);
          state.equipmentInfo = info;
          let arr = info.split(" ");
          state.equipmentInfo = arr.join(" ");
          console.log("arr", arr, state.equipmentInfo);
          state.receiveData = [];
          break;
        case "0a": // 接收霍尔传感器数据
          console.log("霍尔数据", msg);
          // 偶数圈发送拍摄型号
          // 当前圈数等于总圈数发送结束信号
          let data1 = msg.slice(0, 2);
          let data2 = msg.slice(2, 4);
          let data3 = msg.slice(4, 6);
          let data4 = msg.slice(6, msg.length);
          let turns = parseInt(data1, 16) * 256 + parseInt(data2, 16);
          let turnsCount = parseInt(data3, 16) * 256 + parseInt(data4, 16);

          state.receiveData = [];
          this.dispatch("takingPicturesFunc", { turns, turnsCount });

          break;
        case "10": // 复位机器人
          message.success("机器人已经复位");
          state.receiveData = [];
          state.isStop = true;
          break;
        case "11": // 机器人开始工作
          message.success("机器人已经开始工作");
          state.receiveData = [];
          state.isStop = false;
          break;
        case "12": // 机器人暂停工作
          message.success("机器人已经暂停工作");
          state.receiveData = [];
          state.isStop = true;
          break;
        case "0c": // 收到的形变数据
          this.checkResults = false;
          state.sendData = [];
          state.receiveData = [];
          state.step = state.step + 1;
          if (state.step <= state.dataSource.length - 1) {
            this.dispatch("submitData");
          } else {
           // this.$store.dispatch("robotPause");
          
         //  robotPause()
            clearTimeout(state.timerOut);
            state.timerOut = null;
            message.success("数据传输完毕");
          }
          break;
      }
    },
    // 数据校验
    dataValidation(state, hexStr) {
      let startStr = hexStr.slice(0, 2);
      let endStr = hexStr.slice(hexStr.length - 2, hexStr.length);
      if (
        startStr == "55" &&
        (endStr == "0a" || endStr == "0d") &&
        hexStr.length > 4
      ) {
        this.commit("lengthValidation", hexStr);
      } else if (startStr == "55" && endStr != "0a" && endStr != "0d") {
        console.log("帧头部分");
        state.isAwait = true;
        state.receiveData.push(hexStr);
      } else if (
        startStr != "55" &&
        endStr != "0a" &&
        endStr != "0d" &&
        state.receiveData.length > 0
      ) {
        console.log("收到中间部分");
        state.receiveData.push(hexStr);
      } else {
        console.log("收到帧尾部分");
        if (state.receiveData.length > 0) {
          state.receiveData.push(hexStr);
          let receiveDataStr = state.receiveData.join("");
          this.commit("lengthValidation", receiveDataStr);
        }
      }
    },
    // 数据长度校验
    lengthValidation(state, hexStr) {
      console.log("hexStr", hexStr, hexStr.length);
      state.code = hexStr.slice(2, 4);
      let receiveDataLength = 0;
      switch (state.code) {
        case "06": // 获取设备信息
          console.log("设备信息长度校验");
          receiveDataLength = 31 * 2;
          break;
        case "0a": // 接收霍尔传感器数据
          console.log("霍尔数据长度校验");
          receiveDataLength = 9 * 2;
          break;
        case "10": // 复位机器人
          console.log("复位机器人长度校验");
          receiveDataLength = 5 * 2;
          break;
        case "11": // 机器人开始工作
          console.log("机器人开始工作长度校验");
          receiveDataLength = 5 * 2;
          break;
        case "12": // 机器人暂停工作
          console.log("机器人暂停工作长度校验");
          receiveDataLength = 5 * 2;
          break;
        case "0c": // 收到的形变数据
          console.log("形变数据长度校验");
          receiveDataLength = state.sendData.length * 2;
          break;
      }
      console.log(hexStr.length, receiveDataLength);
      hexStr.length == receiveDataLength
        ? (state.checkResults = true)
        : (state.checkResults = false);
      state.hexMessage = hexStr;
      state.isAwait = false;
    },
    // 表格数据重置
    dataReset(state) {
      state.dataSource = [];
      state.step = 0;
    },
    // 更新选择的订单号
    setOrderInfo(state, orderInfo) {
      state.orderInfo = Object.assign({}, state.orderInfo, orderInfo);
    },
    // 更新子订单列表
    setSubOrder(state, subOrder) {
      state.subOrder = subOrder;
    },
    // 更新步骤值
    updateStep(state, step) {
      state.step = step;
    },
    // 更新当前的数据值
    updateCurrentData(state, { currentData, type }) {
      state.currentData = [];
      if (type == "reset") {
        return;
      }
      state.currentData.push(currentData);
    },
    // 记录订单时间戳
    updateOrderTimestamp(state, { type, data }) {
      console.log("存储时间戳", JSON.stringify(data));
      if (type == "add") {
        // 添加
        state.orderTimestamp.push(data);
      }
      if (type == "reset") {
        // 重置
        state.orderTimestamp = [];
      }
    },
  },

  actions: {
    // 连接(关闭)串口
    async connect({ state }) {
      if (!state.support) {
        message.error(
          "浏览器不支持串口服务，请使用chrome浏览器（浏览器版本：89+）"
        );
        return;
      }
      if (state.isConnect) {
        await state.reader.cancel();
        await state.writer.close();
        await state.port.close();
        state.isConnect = false;
        message.success("已经断开与串口设备的连接");
        return;
      }
      // 提示用户选择一个串口
      state.port = await navigator.serial.requestPort();

      try {
        // 等待串口打开  (baudRate：波特率)
        await state.port.open({
          baudRate: 115200,
          dataBits: 8, // 数据位
          stopBits: 1, // 停止位
          parity: "none", // 奇偶校验
        });
        state.isConnect = true;
        message.success("串口设备已连接");
        // console.log('port', port)

        // 串行写入数据
        state.writer = state.port.writable.getWriter();

        state.reader = state.port.readable.getReader();

        this.dispatch("getEquipmentInfo");

        // console.log('port.readable', port.readable)

        // 监听来自串行设备的数据
        while (true) {
          const { value, done } = await state.reader.read();
          this.commit("receiveDataFuc", value);
          if (done) {
            // 允许稍后关闭串口。
            state.reader.releaseLock();
            break;
          }
        }
      } catch (error) {
        console.log(error)
        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
        message.error("串口打开失败，请检查串口是否已经被占用");
      }
    },
    // 发送数据
    async submitData({ state, commit }) {
    
      if (state.dataSource.length == 0) {
        message.error("请添加数据");
        return;
      }
      // 更新当前发送的数据
      commit("updateCurrentData", { currentData: [], type: "reset" });
      clearTimeout(state.timerOut);
      state.timerOut = null;
      //   inputObject数据重置
      for (let dataKey in state.inputObject) {
        state.inputObject[dataKey].sum = 0;
      }

      let commonArray = ["0X55", "0X0C", "0X40"];
      let stepArray = ["0X00", decToHex(state.step + 1)];
      console.log(state.dataSource)
      console.log('1111111111111111111111111111111111111111111111111111111111111111')
      //   let currentStep =
      //   stepArray.push(currentStep)
      let allStepArray = ["0X00", decToHex(state.dataSource.length)];

      commonArray.push(...[...stepArray, ...allStepArray]);

      let inputData = [];
      // 将表格的数据与inputData数据对应
      for (let dataKey in state.dataSource[state.step]) {
        state.inputObject[dataKey]
          ? (state.inputObject[dataKey].sum =
              state.dataSource[state.step][dataKey])
          : "";
      }
      //  转换inputObject的数据
      for (let dataKey in state.inputObject) {
        let hex = decToHex(state.inputObject[dataKey].sum);
        inputData.push("0X00", hex);
      }

      commonArray.push(...inputData);

      // 校验和
      let result = 0;
      commonArray.forEach((item) => {
        result = result + parseInt(item);
      });
      let resultHex = decToHex(result);
      commonArray.push(resultHex);

      commonArray.push("0X0D"); // 结尾

      console.log("commonArray", commonArray);
    
      state.sendData = [];
      state.sendData.push(...commonArray);
      console.log(commonArray)
      console.log('啊啊啊啊啊啊啊啊啊啊啊啊啊啊data')
      let data = new Uint8Array(commonArray);
      console.log(data)
      await state.writer.write(data);
      state.timerOut = setTimeout(() => {
        message.error("响应超时，请检查设备");
      }, 10000);

     // this.dispatch("robotStar");
     
    },
    // 获取设备信息
    async getEquipmentInfo({ state }) {
      console.log("获取设备信息");
      let data = new Uint8Array([
        "0X55",
        "0X05",
        "0X00",
        "0X00",
        "0X5A",
        "0X0D",
      ]);
      await state.writer.write(data);
    },
    // 机器人复位
    async robotReset({ state }) {
      if (!state.isConnect) {
        message.error("请先连接设备");
        return;
      }
      console.log("执行机器人复位指令");
      let data = new Uint8Array([
        "0X55",
        "0X10",
        "0X00",
        "0X00",
        "0X65",
        "0x0D",
      ]);
      await state.writer.write(data);
    },
    // 机器人开始工作
    async robotStar({ state }) {
      if (!state.isConnect) {
        message.error("请先连接设备");
        return;
      }
      console.log("执行机器人开始工作指令");
      let data = new Uint8Array([
        "0X55",
        "0X11",
        "0X00",
        "0X00",
        "0X66",
        "0x0D",
      ]);
      console.log(data);
      await state.writer.write(data);
    },
    // 机器人暂停工作
    async robotPause({ state }) {
      if (!state.isConnect) {
        message.error("请先连接设备");
        return;
      }
      console.log("执行机器人暂停工作指令");
      let data = new Uint8Array([
        "0X55",
        "0X12",
        "0X00",
        "0X00",
        "0X67",
        "0x0D",
      ]);
      await state.writer.write(data);
    },
    // 拍摄照片
    takingPicturesFunc({ state, commit }, turnNumber) {
      let { turns, turnsCount } = turnNumber;

      let action = turns % 2 == 0 ? "start" : "stop";
      let index = turns % 2 == 0 ? (turns - 4) / 2 : (turns - 3) / 2;
      let maxCount = turnsCount + 4;

      console.log(
        "当前圈数",
        turns,
        "返回总圈数",
        turnsCount,
        "实际总圈数",
        maxCount
      );
      if (turns >= 3 && turns < 4) {
        index = 0;
        // 更新当前发送的数据
        commit("updateCurrentData", {
          currentData: state.dataSource[index],
          type: "update",
        });
      }
      if (turns < 4) {
        // this.dispatch("robotReset");
        return;
      }
      if (index > state.subOrder.length - 1) {
        index = state.subOrder.length - 1;
      }

      // 更新当前发送的数据
      commit("updateCurrentData", {
        currentData: state.dataSource[index],
        type: "update",
      });
      // 记录订单时间戳


      // if (index == state.subOrder.length - 1) {
      //   this.dispatch("updateStatusFunc");
      // }
      // if (turns >= maxCount) {
      //   action = "stop";
      //   index = state.subOrder.length - 1;
      // }
      if (turns == maxCount) {
        //alert('fuwei')
        this.dispatch("robotPause");
       // alert('fuweiend')
        //
       // this.dispatch("updateStatusFunc");
      }

      // this.dispatch("takingPicturesRequest", { action, index });
    },
    // 采集子订单时间戳
    addSubLogFuntion({ state, commit }) {
      addSubLog({
        data: state.orderTimestamp,
      }).then((res) => {
        if (res.code == 200) {
          message.success("采集完成");
          commit("updateOrderTimestamp", {
            type: "reset",
          });
        } else {
          message.error(res.msg);
        }
      });
    },
    // 更新订单状态
    updateStatusFunc({ state, dispatch }) {
      console.log("数据采集完成，更新订单状态");
      let orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
      updateStatus({
        order_id: orderInfo.order_id,
        order_status: 2,
      }).then((res) => {
        console.log("更新状态", res);
        if (res.code == 200) {
          
          console.log("state.EventBus", state.EventBus);
          dispatch("addSubLogFuntion");
          state.EventBus.$emit("completeModal");
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 发送数据
    createSubOrderFun({ state, commit, dispatch }) {
      if (state.dataSource.length == 0) {
        message.error("请先上传数据");
        return;
      }
      let size_ids = [];
      state.dataSource.forEach((item) => {
        size_ids.push(item.size_id * 1);
      });
      createSubOrder({
        size_ids,
        order_id: state.orderInfo.id,
      })
        .then((res) => {
          console.log("创建子订单", res);
          if (res.code == 200) {
            commit("updateStep", 0); // 重置步骤值
            commit("setSubOrder", res.data);
            dispatch("submitData");
          }
        })
        .catch((error) => {
          console.log("adfafa");
          message.error(error);
        });
    },
    // 拍照的请求
    // takingPicturesRequest({ state }, { action, index }) {
    //   // action,
    //   // ucode: state.subOrder[index].ordersn,
    //   // ordersn: state.orderInfo.sn
    //   // "action": "start",
    //   // "ucode": "202107300935228765",
    //   // "ordersn": "202108181600001234",
    //   // axios.get(`http://127.0.0.1:7999/CamServer?action=start&ucode=202107300935228766&ordersn=202108181600001234`).then(res => {
    //   // axios
    //   //   .get(
    //   //     `http://127.0.0.1:7999/CamServer?action=${action}&ucode=${state.subOrder[index].ordersn}&ordersn=${state.orderInfo.sn}`
    //   //   )
    //   //   .then((res) => {
    //   //     console.log("拍照", res);
    //   //     console.log('res == "{Result : OK}"', res.data == "{Result : OK}");
    //   //     if (res.data == "{Result : OK}") {
    //   //       console.log(`相机${action}`);
    //   //     } else {
    //   //       this.dispatch("takingPicturesRequest", { action, index });
    //   //       console.log("拍照数据错误");
    //   //     }
    //   //     // if(res.data.)
    //   //   })
    //   //   .catch((error) => {
    //   //     console.log("拍照错误", error);
    //   //   });
    //   // takingPictures({
    //   //     action,
    //   //     ucode: state.subOrder[index].ordersn,
    //   //     ordersn: state.orderInfo.sn
    //   //     // "action": "start",
    //   //     // "ucode": "202107300935228765",
    //   //     // "ordersn": "202108181600001234",
    //   // }).then(res => {
    //   //     console.log('拍照', res)
    //   //     if (res == "Not Found") {
    //   //         this.dispatch("takingPicturesRequest", { action, index })
    //   //     } else {
    //   //         console.log(`相机${action}`)
    //   //     }
    //   //     // if(res.data.)
    //   // }).catch(error => {
    //   //     console.log("拍照错误", error)
    //   // })
    // },
    // 采集子订单拍照数据
  },
  getters: {
    // 获取表格数据
    getDataSource: (state) => state.dataSource,
    // 获取inputObject
    getInputObject: (state) => state.inputObject,
    // 获取连接状态
    getIsConnect: (state) => state.isConnect,
    // 获取设备信息
    getEquipmentInfo: (state) => state.equipmentInfo,
    // 获取步骤值
    getStep: (state) => state.step,
    // 获取选择的订单信息
    getOrderInfo: (state) => state.orderInfo,
    // 获取当前形变数据
    getCurrentData: (state) => state.currentData,
  },
};

export default serialPort;
