/**
 * 数据录入输入框配置项
 */
export default {
  x4: {
    index: 1,
    name: "x4 胸部，右肩头",
    title: "右肩头",
    sum: 0,
  },
  x6: {
    index: 2,
    name: "x6 胸部，右胸高",
    title: "右胸高",
    sum: 10,
  },
  x1: {
    index: 3,
    name: "x1 胸部，颈部（脖子）",
    title: "颈部",
    sum: 2,
  },
  no1: {
    index: 4,
    name: "no1",
    title: "",
    sum: 0,
  },
  x7: {
    index: 5,
    name: "x7 胸部，前胸片",
    title: "前胸片",
    sum: 0,
  },
  x3: {
    index: 6,
    name: "x3 胸部，左肩头",
    title: "左肩头",
    sum: 0,
  },
  x5: {
    index: 7,
    name: "x5 胸部，左胸高",
    title: "左胸高",
    sum: 0,
  },
  x8: {
    index: 8,
    name: "x8 胸部，后胸片",
    title: "后胸片",
    sum: 0,
  },
  x2: {
    index: 9,
    name: "x2 胸部，挑肩部",
    title: "挑肩部",
    sum: 0,
  },
  no2: {
    index: 10,
    name: "no2",
    title: "",
    sum: 0,
  },
  y6: {
    index: 11,
    name: "y6 腰部，前腰下片",
    title: "前腰下片",
    sum: 0,
  },
  y4: {
    index: 12,
    name: "y4 腰部，右腰片",
    title: "右腰片",
    sum: 0,
  },
  y7: {
    index: 13,
    name: "y7 腰部，后腰片",
    title: "后腰片",
    sum: 0,
  },
  no3: {
    index: 14,
    name: "no3",
    title: "",
    sum: 0,
  },
  y5: {
    index: 15,
    name: "y5 腰部，前腰上片",
    title: "前腰上片",
    sum: 0,
  },
  no4: {
    index: 16,
    name: "no4",
    title: "",
    sum: 0,
  },
  y2: {
    index: 17,
    name: "y2 腰部，腰下高",
    title: "腰下高",
    sum: 0,
  },
  y3: {
    index: 18,
    name: "y3 腰部，左腰片",
    title: "左腰片",
    sum: 0,
  },
  y1: {
    index: 19,
    name: "y1 腰部，腰上高",
    title: "腰上高",
    sum: 0,
  },
  no5: {
    index: 20,
    name: "no5",
    title: "",
    sum: 0,
  },
  t2: {
    index: 21,
    name: "t2 臀部，右臀片",
    title: "右臀片",
    sum: 0,
  },
  t5: {
    index: 22,
    name: "t5 臀部，后臀上片",
    title: "后臀上片",
    sum: 0,
  },
  t4: {
    index: 23,
    name: "t4 臀部，前臀下片",
    title: "前臀下片",
    sum: 0,
  },
  no6: {
    index: 24,
    name: "no6",
    title: "",
    sum: 0,
  },
  no7: {
    index: 25,
    name: "no7",
    title: "",
    sum: 0,
  },
  no8: {
    index: 26,
    name: "no8",
    title: "",
    sum: 0,
  },
  t3: {
    index: 27,
    name: "t3 臀部，前臀上片",
    title: "前臀上片",
    sum: 0,
  },
  t1: {
    index: 28,
    name: "t1 臀部，左臀片",
    title: "左臀片",
    sum: 0,
  },
  t6: {
    index: 29,
    name: "t6 臀部，后臀下片",
    title: "后臀下片",
    sum: 0,
  },
  no9: {
    index: 30,
    name: "no9",
    title: "",
    sum: 0,
  },
};
