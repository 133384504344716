import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: { requireAuth: false },
    redirect: "/login",
  },
  {
    path: "/home",
    name: "Home",
    meta: { requireAuth: true },
    component: () => import("../views/Home.vue"),
    redirect: "/index",
    children: [
      // 首页
      {
        path: "/index",
        name: "index",
        meta: { requireAuth: true },
        component: () => import("../views/index.vue"),
      },
      // 通知，留言
      {
        path: "/information",
        name: "information",
        meta: { requireAuth: true },
        component: () => import("../views/information/information.vue"),
      },
      // 用户设置
      {
        path: "/user",
        name: "user",
        component: () => import("../views/user/user.vue"),
      },
      // 确认码
      {
        path: "/confirmation",
        name: "confirmation",
        meta: { requireAuth: true },
        component: () =>
          import("../views/confirmationCode/confirmationCode.vue"),
      },
      //模型管理
      {
        path: "/model",
        name: "model",
        meta: { requireAuth: true },
        component: () => import("../views/model/model.vue"),
      },
      // 机器人管理
      {
        path: "/robot",
        name: "robot",
        meta: { requireAuth: true },
        component: () => import("../views/robot/robot.vue"),
      },
      // 客户管理
      {
        path: "/client",
        name: "client",
        meta: { requireAuth: true },
        component: () => import("../views/clientPage/client.vue"),
      },
      // 文件上传
      {
        path: "/uploadFile",
        name: "uploadFile",
        meta: { requireAuth: true },
        component: () => import("../views/uploadFile/uploadFile.vue"),
      },
      // 紧急停止
      {
        path: "/stop",
        name: "stop",
        meta: { requireAuth: true },
        component: () => import("../views/stop/stop.vue"),
      },
      // 优惠券
      {
        path: "/discountCoupon",
        name: "discountCoupon",
        meta: { requireAuth: true },
        component: () => import("../views/discountCoupon/discountCoupon.vue"),
      },
      // 今日采集计划
      {
        path: "/todayPlan",
        name: "todayPlan",
        meta: { requireAuth: true },
        component: () => import("../views/collect/collectPlan.vue"),
      },
      // 明日采集计划
      {
        path: "/tomorrowPlan",
        name: "tomorrowPlan",
        meta: { requireAuth: true },
        component: () => import("../views/collect/collectPlan.vue"),
      },
      // 采集工作
      {
        path: "/collectWork",
        name: "collectWork",
        meta: { requireAuth: true },
        component: () => import("../views/collect/collectWork.vue"),
      },
      // 本地采集
      {
        path: "/localCollect",
        name: "localCollect",
        meta: { requireAuth: true },
        component: () => import("../views/collect/localCollect.vue"),
      },
      // 本地申请数据分析
      {
        path: "/localAnanlyze",
        name: "localAnanlyze",
        meta: { requireAuth: true },
        component: () => import("../views/analyze/analyze.vue"),
      },
      // 网络申请数据分析
      {
        path: "/webAnanlyze",
        name: "webAnanlyze",
        meta: { requireAuth: true },
        component: () => import("../views/analyze/analyze.vue"),
      },
      // 本月申请数据分析
      {
        path: "/monthAnanlyze",
        name: "monthAnanlyze",
        component: () => import("../views/analyze/analyze.vue"),
      },
      // 本年申请数据分析
      {
        path: "/yearAnanlyze",
        name: "yearAnanlyze",
        meta: { requireAuth: true },
        component: () => import("../views/analyze/analyze.vue"),
      },
      // 编辑页面
      {
        path: "/edit",
        name: "edit",
        meta: { requireAuth: true },
        component: () => import("../views/edit/edit.vue"),
      },
      // 标签管理
      {
        path: "/tags",
        name: "tags",
        meta: { requireAuth: true },
        component: () => import("../views/tags/tags.vue"),
      },
      // 内容管理
      {
        path: "/content",
        name: "content",
        meta: { requireAuth: true },
        component: () => import("../views/content/content.vue"),
      },
      // 内容编辑
      {
        path: "/contentEdit",
        name: "contentEdit",
        meta: { requireAuth: true },
        component: () => import("../views/content/contentEdit.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    meta: { requireAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    },
  },
  // 登陆
  {
    path: "/login",
    name: "login",
    meta: { requireAuth: false },
    component: () => import("../views/login/login.vue"),
  },
];
/**
 * 以下四行代码是为了解决下面的这个问题
 * 关于VUE项目中报NavigationDuplicated: Avoided redundant navigation to current location 的错
 */
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
