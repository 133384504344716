import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Antd
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import './main.less';
import { ConfigProvider } from "ant-design-vue"; //引用
// require('../mock/index.js');
Vue.component(ConfigProvider.name, ConfigProvider); //注册
Vue.use(Antd);
Vue.prototype.$EventBus = new Vue();

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("ACCESS_TOKEN")) {
      // 判断本地是否存在access_token
      next();
    } else {
      // 未登录,跳转到登陆页面，并且带上 将要去的地址，方便登陆后跳转。
      next({
        path: "/",
        query: { referrer: to.fullPath },
      });
    }
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
